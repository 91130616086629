import { DashboardApi, SignificantChangesList } from '@aminsights/contract';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

import {
  SC_DASHBOARD,
  SIGNIFICANT_CHANGES,
} from '../watchlist-hooks/query-keys';

type UseDashboardSignificantChangesResponse = SignificantChangesList;
type UseDashboardSignificantChangesError = unknown; // If API had error types place here
type UseDashboardSignificantChangesOptions = Omit<
  UseQueryOptions<
    UseDashboardSignificantChangesResponse,
    UseDashboardSignificantChangesError
  >,
  'queryKey' | 'queryFn' | 'initialData'
>;

const useDashboardSignificantChanges = (
  isScFlowTreatmentEnabled: boolean, //feature switch prop
  options?: UseDashboardSignificantChangesOptions,
) => {
  return useQuery<
    UseDashboardSignificantChangesResponse,
    UseDashboardSignificantChangesError
  >(
    [SIGNIFICANT_CHANGES, SC_DASHBOARD, isScFlowTreatmentEnabled],
    async () => {
      const api = new DashboardApi(openApiConfig());

      //new prop for the api call, to be removed with feature switch
      const result = await api.getDashboardSignificantChanges({
        params: { excludeFlow: isScFlowTreatmentEnabled },
      });

      return result.data;
    },
    options,
  );
};

export default useDashboardSignificantChanges;
