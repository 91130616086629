import { ChartColorsWithBrightEffect } from '@aminsights/shared';
import { Scatter, ScatterConfig } from '@ant-design/charts';
import cx from 'classnames';
import React, { useRef } from 'react';

import { TransparentBrightColorsMap } from '../../Charts/utils/colors';
import {
  ScatterPlotCardDetails,
  ScatterPlotDataWithColor,
} from '../utils/scatter-plot-data';
import style from './style.module.less';

interface Props {
  config: ScatterConfig;
  strokeColor?: string;
  onUpdateLegend?: (
    value: React.SetStateAction<ScatterPlotCardDetails[]>,
  ) => void;
}

interface PlotEvent {
  data: {
    color: string;
    data: ScatterPlotDataWithColor;
  };
}

const BaseScatterPlot: React.FCWithChild<Props> = ({
  config,
  strokeColor,
  onUpdateLegend,
}) => {
  const currentLine = useRef('');

  const handleUpdateLegend = (id: string, color: string, active?: boolean) => {
    onUpdateLegend?.(prev => {
      const currIndex = prev.findIndex(p => p.id === id);
      return prev.map((p, idx) => {
        if (idx === currIndex) {
          return {
            ...p,
            color,
            isActive: active,
          };
        }
        return p;
      });
    });
  };

  const generateAxisTitle = (value: string) => {
    return {
      text: value,
      autoRotate: true,
      style: {
        fontSize: 14,
      },
    };
  };

  //TODO: make configs for all charts sharable when we move logic to backend
  const defaultConfig: Partial<ScatterConfig> = {
    appendPadding: [10, 0, 30, 10],
    localRefresh: false,
    xField: 'standardDeviation',
    yField: 'totalReturnOverPeriod',
    meta: {
      date: {
        range: [0, 1],
      },
    },
    size: 5,
    shape: 'circle',
    pointStyle: {
      fillOpacity: 1,
    },
    yAxis: {
      title: generateAxisTitle('Return'),
      nice: true,
      label: {
        formatter: (text: string) => `${text}%`,
        offset: 13,
        style: {
          fill: '#545576',
        },
      },
      grid: {
        alignTick: true,
        line: {
          style: {
            stroke: strokeColor ?? '#aaa',
          },
        },
      },
    },
    xAxis: {
      title: generateAxisTitle('Std. Dev'),

      label: {
        formatter: (text: string) => `${text}%`,
        offset: 15,
        style: () => {
          return { fill: '#545576' };
        },
      },
      grid: {
        alignTick: true,
        line: {
          style: (_: never) => {
            const stroke = strokeColor;
            return {
              stroke,
              lineDash: [5, 5],
            };
          },
        },
      },
      tickLine: null,
    },
    tooltip: {
      showCrosshairs: true,
      domStyles: {
        'g2-tooltip': {
          backgroundColor: '#313341',
          color: '#FFF',
          minWidth: 5,
        },
      },
      customContent: (_, data) => {
        if (data.length > 0) {
          const dataInfo = data[0];
          return (
            <div
              className={cx(
                'flex justify-between items-center gap-x-1 flex-col gap-y-4',
                style['tooltip'],
              )}
            >
              <span>{dataInfo.data.tooltip}</span>
            </div>
          );
        }
      },
      crosshairs: {
        line: {
          style: {
            fillOpacity: 0.5,
            stroke: '#8C92B1',
            lineWidth: 1,
            lineDash: [4, 5],
            shadowColor: 'black',
            shadowBlur: 25,
            cursor: 'pointer',
          },
        },
      },
    },
    autoFit: true,
    legend: false,
    interactions: [
      {
        type: 'element-active',
      },
    ],
    onEvent: (_, e) => {
      const plotEventData = e.data as PlotEvent['data'];
      if (e.type === 'mouseout' && plotEventData) {
        const { data } = plotEventData;
        const color = data.color;
        const id = data?.id;
        const defaultColor = ChartColorsWithBrightEffect.find(
          d => d.transparent === color,
        )?.transparent;
        if (defaultColor && !(data?.isFeatured || data?.isBenchmark) && id) {
          plotEventData.color = defaultColor;
          currentLine.current = '';
          handleUpdateLegend(id, defaultColor, false);
        }
      } else if (e.type === 'mouseover' && plotEventData) {
        const { data } = plotEventData;
        const color = data.color;
        const activeColor = TransparentBrightColorsMap[color];
        const id = data?.id;
        if (activeColor && id) {
          plotEventData.color = activeColor;
          currentLine.current = id;
          handleUpdateLegend(id, activeColor, true);
        }
      }
    },
  };

  if (defaultConfig.yAxis && defaultConfig.data) {
    const yAxisValues = defaultConfig.data.map(
      d => d.totalReturnOverPeriod,
    ) as number[];
    if (Math.min(...yAxisValues) < 0) {
      defaultConfig.yAxis.verticalFactor = 0.1;
    }
  }

  return (
    <div>
      {/* !!!Don't switch the order of config and defaultConfig */}
      <Scatter {...config} {...defaultConfig} key={'base-scatter-plot'} />
    </div>
  );
};

export default React.memo(BaseScatterPlot);
