import {
  Fund,
  FundRelativeRiskMeasuresDetail,
  PageQueryParametersSortDirectionEnum,
} from '@aminsights/contract';
import {
  BenchmarkType,
  buildFundDetailsPath,
  DATE_PERIOD_FILTER,
  EDateFilterValues,
  getFundShareClassDetailsFromArray,
  isFund,
  LIMIT_FUNDS_FOR_CHARTING,
  MsTimePeriod,
} from '@aminsights/shared';
import { FC, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';

import { Dropdown, PageResults } from '@/components';
import { DataTable } from '@/components';
import { MAX_COMPARE_TOTAL, TABLE_UNIQUE_KEY } from '@/constants';
import { DataTableRenderedAt } from '@/constants/dataTableRenderedAt';
import useWatchlistRiskInfiniteQuery from '@/hooks/query-hooks/watchlist-hooks/useWatchlistRiskInfiniteQuery';
import { useCurrentBucket } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import { useInfiniteScroll } from '@/hooks/useInfiniteScroll';
import useOnCheckedRow from '@/hooks/useOnCheckedRow';
import { parseDateValue, useDatePickerContext } from '@/partials/DatePicker';
import FundTablesActionToast from '@/partials/Toast/FundTablesActionToast';
import { switchTableRowColor } from '@/utils/generate-color';

import {
  flattenInfiniteQueryData,
  getTotalFromPaginatedData,
} from '../../utils';
import RiskColumns from './WatchlistRiskColumns';

const WatchlistRiskTable: FC<{ isActive: boolean }> = ({ isActive }) => {
  const history = useHistory();
  const currentBucket = useCurrentBucket();
  const [sortKey, setSortKey] = useState('fundName');
  const [sortDirection, setSortDirection] =
    useState<PageQueryParametersSortDirectionEnum>(
      PageQueryParametersSortDirectionEnum.Asc,
    );

  const { value: datePickerValue, handleChange: setPeriod } =
    useDatePickerContext();

  const { data, isLoading, fetchNextPage } = useWatchlistRiskInfiniteQuery({
    sortKey: sortKey,
    sortDirection: sortDirection,
    bucketId: currentBucket?.id,
  });

  const flattenedData = flattenInfiniteQueryData(data);
  const totalNumberOfItems = getTotalFromPaginatedData(data);

  const { setPage, lastElementRef } = useInfiniteScroll(
    totalNumberOfItems,
    flattenedData,
    ({ page }) => {
      if (page > 1) {
        fetchNextPage({ pageParam: page });
      }
    },
    [currentBucket?.id, sortDirection],
  );

  const allowedPeriodValues = [
    EDateFilterValues['1YR'],
    EDateFilterValues['3YR'],
    EDateFilterValues['5YR'],
  ];

  const allowedPeriodsForDropdown = DATE_PERIOD_FILTER.filter(p =>
    allowedPeriodValues.some(apv => apv === p.value),
  );

  const period = useMemo(() => {
    if (allowedPeriodValues.includes(datePickerValue.mode)) {
      return datePickerValue.mode;
    }
    return EDateFilterValues['1YR'];
  }, [datePickerValue]);

  const isins = useMemo(() => {
    return flattenedData.map(item => {
      if (isFund(item)) {
        return item.shareClassDetails.isin;
      }
    });
  }, [flattenedData]);

  const checkedRowState = useOnCheckedRow<Fund>(isins.length || 0);
  const { checkedRows, setCheckedRows, setIsAllCheckboxChecked } =
    checkedRowState;

  let timePeriod: MsTimePeriod = MsTimePeriod.ONE_YR;
  switch (datePickerValue.mode) {
    case EDateFilterValues['1YR']:
      timePeriod = MsTimePeriod.ONE_YR;
      break;
    case EDateFilterValues['3YR']:
      timePeriod = MsTimePeriod.THREE_YRS;
      break;
    case EDateFilterValues['5YR']:
      timePeriod = MsTimePeriod.FIVE_YRS;
      break;
  }

  useEffect(() => {
    setCheckedRows([]);
  }, [isActive]);

  const columns = RiskColumns({
    rowSelectionState: checkedRowState,
    dateRange: timePeriod,
    sortDirection,
    onSelectAll: checked => {
      if (checked) {
        const topIsins = flattenedData
          .slice(0, LIMIT_FUNDS_FOR_CHARTING)
          .map(item => item.shareClassDetails.isin);
        setCheckedRows(topIsins ?? []);
      } else {
        setCheckedRows([]);
      }
    },
  });

  const dropdownPortal = document.getElementById('dropdown-portal');
  const totalIsinsPortal = document.getElementById('total-isins-portal');

  return (
    <>
      {isActive &&
        dropdownPortal &&
        ReactDOM.createPortal(
          <Dropdown
            rounded
            label="Select value"
            value={period}
            onSelect={(selectedValue: EDateFilterValues) => {
              setPeriod(parseDateValue(selectedValue), selectedValue);
            }}
            items={allowedPeriodsForDropdown}
            defaultValue={period}
          />,
          dropdownPortal,
        )}
      {isActive &&
        totalIsinsPortal &&
        ReactDOM.createPortal(
          <PageResults
            className={'min-w-28'}
            totalCount={totalNumberOfItems ?? 0}
          />,
          totalIsinsPortal,
        )}
      <DataTable
        className="watchlist-tr-padding"
        lastElementRef={lastElementRef}
        uniqueKey={TABLE_UNIQUE_KEY}
        loading={isLoading}
        columns={columns}
        data={flattenedData}
        onSort={async (
          key: string,
          direction: PageQueryParametersSortDirectionEnum,
        ) => {
          if (key === 'upsideDownsideSortKey') {
            //this swaps the sort key between upside and downside on each sort
            const type: keyof Pick<
              FundRelativeRiskMeasuresDetail,
              'captureRatioTypeOne' | 'captureRatioTypeTwo'
            > =
              direction === PageQueryParametersSortDirectionEnum.Asc
                ? 'captureRatioTypeTwo'
                : 'captureRatioTypeOne';
            setSortKey(
              `relativeRiskMeasuresDetail.${type}.${BenchmarkType.MORNINGSTAR_CATEGORY}.${timePeriod}`,
            );
          } else {
            setSortKey(key);
          }
          setSortDirection(direction);
          setPage(1); // Resets the infinite scroll page
        }}
        onRow={id => history.push(buildFundDetailsPath(id))}
        computeRowStyle={rowData => {
          const isFundRow = isFund(rowData);
          if (!isFundRow) return {};
          const isFeaturedRow = currentBucket?.funds.find(
            f => rowData.shareClassDetails.isin === f?.isin,
          )?.isFeatured;
          return {
            backgroundColor: switchTableRowColor(!!isFeaturedRow, !isFundRow),
            cursor: 'pointer',
          };
        }}
      />
      {checkedRows.length > 0 && (
        <FundTablesActionToast
          isins={checkedRows}
          count={checkedRows?.length || 0}
          onClearCheckedRows={(isins: string[]) => {
            setCheckedRows(isins);
            setIsAllCheckboxChecked(false);
          }}
          checkedRowsClassDetails={checkedRows
            .slice(0, MAX_COMPARE_TOTAL)
            .map(i => getFundShareClassDetailsFromArray(flattenedData, i))}
          dataTableRenderedAt={DataTableRenderedAt.Watchlist}
        />
      )}
    </>
  );
};

export default WatchlistRiskTable;
