// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xT6qciB2Wr80xYpNdMBN {\n  font-size: 1.25rem;\n  font-weight: 700;\n  line-height: 32px;\n  margin-bottom: 1rem;\n}\n.VXm4FEgW9Y4xawrwRZGQ {\n  overflow: hidden;\n}\n.VXm4FEgW9Y4xawrwRZGQ h5 {\n  font-size: 0.875rem;\n  color: #313341;\n  font-weight: 700;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/app/Charts/style.module.less"],"names":[],"mappings":"AAGE;EACE,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AAFJ;AAKE;EACE,gBAAA;AAHJ;AAEE;EAKM,mBAAA;EACA,cAAA;EACA,gBAAA;AAJR","sourcesContent":["@import '@/less/partials/_variables.less';\n\n.section-charts {\n  &-title {\n    font-size: @txt-lg;\n    font-weight: @weight-bold;\n    line-height: @line-height-lg;\n    margin-bottom: 1rem;\n  }\n\n  &__card {\n    overflow: hidden;\n\n    :global {\n      h5 {\n        font-size: @txt-sm;\n        color: @darkest;\n        font-weight: @weight-bold;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section-charts-title": "xT6qciB2Wr80xYpNdMBN",
	"section-charts__card": "VXm4FEgW9Y4xawrwRZGQ"
};
export default ___CSS_LOADER_EXPORT___;
