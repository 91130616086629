import { APP_ROUTE_WATCHLIST } from '@aminsights/shared';
import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import { ReactComponent as IconPencil } from '@/assets/svg/icons/icon-pencil-v2.svg';
import { Button } from '@/components';
import { useCurrentWatchlist } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import { generatePaths, WATCHLIST_SUB_ROUTES } from '@/router/routes';
import WatchlistRouter from '@/router/Watchlist';
import WatchlistSubRouter from '@/router/WatchlistSub';

import BasePageWithMetadata from '../../BasePageWithMetadata';
import { BucketsScrollTab } from './components';
import style from './style.module.less';

const WatchlistComponent = () => {
  const currentWatchlist = useCurrentWatchlist();
  const bucketList = currentWatchlist.data?.buckets;
  const history = useHistory();
  const getCurrentBucketId = () => {
    const url = history.location.pathname;
    const result = url.substring(url.lastIndexOf('/') + 1);
    return result.substring(result.lastIndexOf('-') + 1);
  };

  const activeBucketTab =
    bucketList &&
    bucketList.find(bucket => {
      return bucket.id === getCurrentBucketId();
    });

  return (
    <BasePageWithMetadata
      title={`Watchlist${
        activeBucketTab ? ` - ${activeBucketTab.name}` : ' - All'
      }`}
    >
      <div className={style['section-watchlist-heading']}>
        <h2 className="text-xl font-bold">Watchlist</h2>
      </div>
      <div className={style['section-watchlist-tabs']}>
        <div className={style['bucket-tab-wrapper']}>
          <BucketsScrollTab />
        </div>
        <div className={style['transparent-bg']} />
        <div className={style['bucket-btn-wrapper']}>
          <Button
            type="primary"
            className={style['edit-bucket-btn']}
            data-test-id="bucketEditButton"
            onClick={() => {
              history.push(`/${APP_ROUTE_WATCHLIST}/manage-buckets`);
            }}
          >
            <IconPencil className="text-xl icon" />

            <p className="hidden ml-2 text-sm font-medium sm:block">
              Edit Buckets
            </p>
          </Button>
        </div>
      </div>
      <div className="py-4 lg:px-4 lg:rounded-lg">
        <WatchlistSubRouter />
      </div>
    </BasePageWithMetadata>
  );
};

const Watchlist: React.FCWithChild = () => (
  <section className={style['section-watchlist']}>
    <Switch>
      <Route
        path={[
          ...generatePaths(WATCHLIST_SUB_ROUTES, APP_ROUTE_WATCHLIST),
          APP_ROUTE_WATCHLIST,
        ].map(p => `/${p}`)}
        exact={true}
      >
        <WatchlistComponent />
      </Route>
      <WatchlistRouter />
    </Switch>
  </section>
);

export default Watchlist;
