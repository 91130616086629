import { APP_ROUTE_WATCHLIST, BucketErrors } from '@aminsights/shared';
import { Dropdown, Menu } from 'antd';
import cx from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ReactComponent as AddIcon } from '@/assets/svg/icons/icon-add-item.svg';
import { ReactComponent as InfoIcon } from '@/assets/svg/icons/icon-info.svg';
import { ReactComponent as MeatballMenu } from '@/assets/svg/meatball-menu.svg';
import Button from '@/components/Button';
import Drawer from '@/components/Drawer';
import Tooltip from '@/components/Tooltip';
import { APP_ACTIONS } from '@/constants';
import { STATIC_DATA_ADD_BUCKET_MODAL } from '@/constants/modals-static-data';
import { useAppContext } from '@/context/AppContext';
import { useBenchmarkOptions } from '@/hooks/query-hooks/benchmark-hooks/useManageBenchmarks';
import { useCreateNewBucket } from '@/hooks/query-hooks/bucket-hooks/useManageBuckets';
import { useCurrentWatchlist } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import BucketDefault from '@/partials/Bucket/Default';
import BucketSkeleton from '@/partials/Bucket/Default/Skeleton';
import EmptyBucketState from '@/partials/ManageBuckets/EmptyBucketState';
import AddEntityWithBenchmarkModal from '@/partials/Modal/AddEntityWithBenchmarkModal';
import SectionBackButton from '@/partials/Sections/SectionBackButton';
import { toCamelCase } from '@/utils/toCamelCase';

import BasePageWithMetadata from '../../BasePageWithMetadata';
import AddToBucket from './components/AddToBucket';
import SearchBucket from './components/SearchBucket';
import style from './style.module.less';

const Buckets: React.FCWithChild = () => {
  const history = useHistory();
  const location = useLocation();
  const appContext = useAppContext();
  const createNewBucket = useCreateNewBucket();
  const { data, isLoading } = useCurrentWatchlist();
  const [addToBucket, toggleAddToBucket] = useState(false);
  const [highlightedBucket, setHighlightedBucket] = useState<string | null>(
    null,
  );
  const [isAddNewBucketModalOpen, setIsAddNewBucketModalOpen] = useState(false);
  const { data: benchmarkOptionsResponse } = useBenchmarkOptions();

  const searchParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location?.search]);

  const bucketsTableRef = useRef<HTMLDivElement | null>(null);

  const renderBuckets =
    !data?.buckets || isLoading
      ? Array.from(new Array(6), (_, i) => (
          <div key={i} className="mr-4">
            <div
              data-test-id={`buckets${i}`}
              style={{ width: '320px', height: '490px' }}
            >
              <BucketSkeleton loading={true} />
            </div>
          </div>
        ))
      : data.buckets?.map((bucket, index) => (
          <div
            data-test-id={toCamelCase(`bucket${bucket.name}`)}
            key={bucket.id}
            className="mr-4"
          >
            <div style={{ width: '320px', height: '490px' }}>
              <BucketDefault
                id={bucket.id}
                order={index}
                name={bucket.name}
                index={bucket.index}
                funds={bucket.funds}
                active={highlightedBucket === bucket.id}
              />
            </div>
          </div>
        ));

  const bucketMenu = (
    <Menu
      items={[
        {
          label: 'Import funds/trusts',
          key: 'ImportFunds',
          onClick: () => history.push(`/${APP_ROUTE_WATCHLIST}/import-funds`),
        },
      ]}
    />
  );

  const handleAddNewBucketModalOpen = () => {
    setIsAddNewBucketModalOpen(prev => !prev);
  };

  const onSaveClick = async (name: string, benchmarkId?: string) => {
    const benchmarkName =
      benchmarkOptionsResponse?.find(b => b.secId === benchmarkId)?.name ?? '';
    await createNewBucket.mutateAsync({
      name,
      index: benchmarkName,
      indexSecId: benchmarkId || '',
      funds: [],
    });
  };

  useEffect(() => {
    if (searchParams.get('error') === BucketErrors.BUCKET_NONEXISTENT) {
      appContext.dispatch({
        type: APP_ACTIONS.SET_ERROR_MESSAGE,
        payload: 'Bucket does not exist',
      });
    }
    if (searchParams.get('current')) {
      setHighlightedBucket(searchParams.get('current'));
    }
  }, [searchParams]);

  useEffect(() => {
    if (highlightedBucket) {
      const timeout = setTimeout(() => {
        setHighlightedBucket(null);
        searchParams.delete('current');
        history.replace({ search: searchParams.toString() });
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [highlightedBucket]);

  const scrollOnBucketAdd = () => {
    const scrollableTable = bucketsTableRef.current;
    if (scrollableTable) {
      const maxScrollWidth = scrollableTable.scrollWidth;
      if (maxScrollWidth > 0) {
        setTimeout(() => {
          scrollableTable.scrollTo({
            left: maxScrollWidth,
            behavior: 'smooth',
          });
        }, 500);
      }
    }
  };

  return (
    <BasePageWithMetadata title={'Manage Buckets'}>
      <div data-test-id={`${STATIC_DATA_ADD_BUCKET_MODAL.entityName}`}>
        <AddEntityWithBenchmarkModal
          entities={data?.buckets ? data.buckets : []}
          isVisible={isAddNewBucketModalOpen}
          toggleModal={handleAddNewBucketModalOpen}
          onSaveClick={onSaveClick}
          staticModalData={STATIC_DATA_ADD_BUCKET_MODAL}
          scrollOnBucketAdd={scrollOnBucketAdd}
        />
      </div>
      <SectionBackButton previousLabel={'Back'} />
      <section className={cx(style['section-buckets'], 'lg:p-4')}>
        <Drawer isVisible={false} prevText="Back to Search">
          {addToBucket ? (
            <AddToBucket />
          ) : (
            <SearchBucket
              emitAddToBucket={value => toggleAddToBucket(value)}
              fundCount={8}
            />
          )}
        </Drawer>

        <div
          className={cx(
            style['section-buckets__body'],
            'bg-white lg:rounded-lg',
          )}
        >
          <div className={style['section-buckets__body-heading']}>
            <h2 data-test-id="Buckets" className="pr-1">
              Buckets <span>({data?.buckets.length ?? 0})</span>
            </h2>
            <Tooltip
              title="Maximum of 11 funds/trusts per bucket."
              placement="bottom"
              color="#3E414B"
              overlayClassName={style['tooltip-rounded']}
            >
              <InfoIcon className={style['icon-info']} />
            </Tooltip>

            <div className={style['section-buckets__body-heading-actions']}>
              <Button
                type="primary"
                onClick={() => {
                  setIsAddNewBucketModalOpen(true);
                }}
                data-test-id="bucketAddButton"
                icon={<AddIcon className="icon text-md mb-px" />}
              >
                <p className="hidden text-sm font-medium md:block">
                  Add Bucket
                </p>
              </Button>
              <div data-test-id="dropDownMeatBallMenu" className="m-auto">
                <Dropdown
                  className={style['icon-meatball']}
                  overlay={bucketMenu}
                  placement="bottomRight"
                  trigger={['click']}
                >
                  <MeatballMenu />
                </Dropdown>
              </div>
            </div>
          </div>
          <div className={style['section-buckets__body-content']}>
            <p>
              You can move funds/trusts between buckets and move the order of
              buckets.
            </p>

            <div>
              <div
                className={style['section-buckets__body-content-buckets']}
                ref={bucketsTableRef}
              >
                {renderBuckets && renderBuckets.length ? (
                  renderBuckets
                ) : (
                  <EmptyBucketState />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </BasePageWithMetadata>
  );
};

export default Buckets;
