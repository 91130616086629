import { SignificantChangesThreshold } from '@aminsights/contract';

export const DEFAULT_THRESHOLDS: SignificantChangesThreshold = {
  userId: '',
  flowThreshold: 5,
  fundSizeThreshold: 5,
  holdingsThreshold: 5,
  ocfThreshold: 0.1,
  yieldThreshold: 1,
  premiumDiscountThreshold: 2,
  modifiedDurationThreshold: 5,
};
