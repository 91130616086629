import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { Button, HorizontalScroll, SummaryCard } from '@/components';
import { summaryCardMapping } from '@/components/Card/SummaryCard/types';
import useDashboardSummaryCards from '@/hooks/query-hooks/dashboard-hooks/useDashboardSummaryCards';

import style from './style.module.less';

const Summary = () => {
  const refScroll = useRef<HTMLDivElement>(null);
  const [hasScrollbar, setHasScrollBar] = useState(false);
  const [scrollSteps, setScrollSteps] = useState(0);

  const { data, isLoading } = useDashboardSummaryCards();
  const summaryCards = data;

  useEffect(() => {
    if (refScroll.current) {
      setHasScrollBar(
        refScroll.current?.scrollWidth <=
          refScroll.current?.getBoundingClientRect().width,
      );

      setScrollSteps(refScroll.current?.getBoundingClientRect().width);
    }
  }, [
    refScroll.current?.scrollWidth,
    refScroll.current?.getBoundingClientRect().width,
  ]);

  const onClickScroll = (scroll: number) => {
    if (refScroll?.current) {
      refScroll.current.scrollTo({
        top: 0,
        left: scroll + refScroll?.current.scrollLeft,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div>
      <div className="flex flex-row justify-between pt-2">
        <h2 className="text-xl mb-2 font-bold text-darkest">Dashboard</h2>
        <div className={cx(style['summary-arrows'], { hidden: hasScrollbar })}>
          {(data?.length ?? 0) !== 0 && (
            <>
              <Button
                className={cx(style['btn-arrows'], 'mr-1')}
                type="default"
                onClick={() => onClickScroll(-scrollSteps)}
                icon={<LeftOutlined className={style['icon-bold']} />}
              />
              <Button
                className={style['btn-arrows']}
                onClick={() => onClickScroll(scrollSteps)}
                icon={<RightOutlined className={style['icon-bold']} />}
              />
            </>
          )}
        </div>
      </div>
      <HorizontalScroll
        hideScrollbars
        horizontal
        ref={refScroll}
        className={style['horizontal-scroll-snap']}
      >
        {Array.from(summaryCardMapping.keys()).map(cardType => {
          const summaryCard = summaryCards?.find(
            card => card.cardType === cardType,
          );
          return (
            <SummaryCard
              key={cardType}
              cardItem={summaryCard}
              loading={isLoading}
            />
          );
        })}
      </HorizontalScroll>
    </div>
  );
};

export default Summary;
