export enum TrendType {
  NotApplicable = 'NotApplicable',
  Upward = 'Upward',
  Downward = 'Downward',
}

export enum ChangeTypeEnum {
  FundSize = 'fundSize',
  Flow = 'flow',
  Holdings = 'holdings',
  Ocf = 'ocf',
  Yield = 'yield',
  PremiumDiscount = 'premiumDiscount',
  ManagerChange = 'managerChange',
  StyleDrift = 'styleDrift',
  ModifiedDuration = 'modifiedDuration',
}
