import { useState } from 'react';

import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import ChartEmptyState from '@/partials/Charts/ChartEmptyState';
import RiskReturnPlotTitle from '@/partials/ScatterPlots/risk-return-scatter-plot/RiskReturnPlotTitle';
import RiskReturnScatterPlotV2 from '@/partials/ScatterPlots/risk-return-scatter-plot/RiskReturnScatterPlotV2';

import RiskReturnV2WithPortfolio from '../../../../partials/ScatterPlots/risk-return-scatter-plot/RiskReturnV2WithPortfolio';
import useChartTrustFundsFromSearch from '../utils/useChartFundsFromSearch';
import { useChartingContext } from './ChartingContext';

const RiskReturnChartingToolPage = () => {
  const {
    isins,
    benchmark,
    setIsins,
    hasInvestmentTrust,
    portfolios,
    setPortfolios,
  } = useChartTrustFundsFromSearch();
  const { state: featureSwitchState } = useFeatureSwitchContext();
  const [riskReturnLatestDate, setRiskReturnLatestDate] = useState<
    string | undefined
  >();

  const chartingCtx = useChartingContext();
  const legendData = chartingCtx.chartLegend;
  const chartColors = chartingCtx.chartColors;

  if (!(isins.length || portfolios.length)) {
    const title = featureSwitchState.portfolioInChartingPageEnabled
      ? 'No added funds/trusts/portfolios to chart'
      : 'No added funds/trusts to chart';
    const subTitle = featureSwitchState.portfolioInChartingPageEnabled
      ? 'Search funds/portfolios to chart'
      : 'Search funds to chart';
    return (
      <ChartEmptyState
        title={title}
        subtitle={subTitle}
        isDashboardPage={true}
      />
    );
  }

  const ChartsV2 = featureSwitchState.portfolioInChartingPageEnabled ? (
    <RiskReturnV2WithPortfolio
      onRemoveFund={(id, isPortfolio) => {
        if (isPortfolio) {
          setPortfolios([id], true);
          const targetId = legendData.find(ld => ld.id?.includes(id))?.id || '';
          const targetColor = chartColors[targetId];
          targetColor && chartingCtx.setColorsRemoved(targetColor);
          return;
        }
        const targetId = legendData.find(ld => ld.isin === id)?.id || '';
        const targetColor = chartColors[targetId];
        targetColor && chartingCtx.setColorsRemoved(targetColor);
        setIsins([id], true);
      }}
      showDropdown={true}
      isins={isins}
      benchmarkId={benchmark}
      hasInvestmentTrust={hasInvestmentTrust}
      resetInvReturnFilterOnChange={false}
      portfolios={portfolios}
      chartColors={chartColors}
      riskReturnLatestDate={riskReturnLatestDate}
      updateRiskReturnLatestDate={setRiskReturnLatestDate}
    />
  ) : (
    <RiskReturnScatterPlotV2
      isins={isins}
      benchmarkId={benchmark}
      chartOnly={true}
      onRemoveFund={isin => {
        setIsins([isin], true);
        const targetId = legendData.find(ld => ld.isin === isin)?.id || '';
        const targetColor = chartColors[targetId];
        targetColor && chartingCtx.setColorsRemoved(targetColor);
      }}
      showDropdown={true}
      hasInvestmentTrust={hasInvestmentTrust}
      scatterPlotColors={chartColors}
    />
  );

  return (
    <>
      <RiskReturnPlotTitle
        hasInvestmentTrust={hasInvestmentTrust}
        showDropdown={true}
        riskReturnLatestDate={riskReturnLatestDate}
      />
      {ChartsV2}
    </>
  );
};

export default RiskReturnChartingToolPage;
