import { APP_ROUTE_FUND } from '@aminsights/shared';
import React, { useEffect, useRef, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { useAppContext } from '@/context/AppContext';
import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import FundDetailsSection from '@/pages/app/FundAndInvestmentTrust/components/FundDetailsSection';
import { FundDetailTabs } from '@/pages/app/FundAndInvestmentTrust/components/FundDetailTabs';
import { FundTitleHeader } from '@/pages/app/FundAndInvestmentTrust/components/FundTitleHeader';
import { FUND_SUB_ROUTES, generatePaths } from '@/router/routes';

const FundOrInvestmentDetailsPage: React.FCWithChild = () => {
  const { app } = useAppContext();
  const { isLoading, data, error } = useFundByIsInParam();

  const fundDetailsSectionRef = useRef<HTMLDivElement>(null);
  const [titleHeight, setTitleHeight] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (fundDetailsSectionRef?.current) {
      setTitleHeight(fundDetailsSectionRef.current.clientHeight);
      setHeight(fundDetailsSectionRef.current.clientHeight);
    }
  }, [app?.windowWidth]);

  // Temporary fix to return nothing if the fund is not found
  // TODO: add not found page once design is ready
  if (!isLoading && error) return <></>;

  return (
    <>
      <FundTitleHeader isLoading={isLoading} fund={data?.fund} />
      <FundDetailsSection ref={fundDetailsSectionRef} />
      <FundDetailTabs sectionHeight={height + titleHeight} />
    </>
  );
};

export default function FundOrInvestmentDetails() {
  return (
    <Switch>
      <Route
        exact
        path="/fund/:id/details"
        render={({ match }) => (
          <Redirect to={`/fund/${match.params.id}/details/summary`} />
        )}
      />
      <Route
        exact
        path="/fund/:id/"
        render={({ match }) => {
          return (
            match.params.id !== 'compare' && (
              <Redirect to={`/fund/${match.params.id}/details/summary`} />
            )
          );
        }}
      />
      <Route
        exact
        path={[...generatePaths(FUND_SUB_ROUTES, APP_ROUTE_FUND)].map(p => {
          return `/${p}`;
        })}
      >
        <FundOrInvestmentDetailsPage />
      </Route>
    </Switch>
  );
}
