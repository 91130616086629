import {
  Fund,
  FundBroadAssetClass,
  PageQueryParametersSortDirectionEnum,
} from '@aminsights/contract';
import {
  buildFundDetailsPath,
  calculateExtremumsForFundByBroadAssetClasses,
  CREDIT_QUALITY_TO_SHOW,
  CreditQualityBreakdownTypeCodes,
  getFundShareClassDetailsFromArray,
  isFund,
  LIMIT_FUNDS_FOR_CHARTING,
} from '@aminsights/shared';
import { Switch } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';

import { DataTable, PageResults } from '@/components';
import {
  LEGEND_TYPE_ENUM,
  MAX_COMPARE_TOTAL,
  TABLE_UNIQUE_KEY,
} from '@/constants';
import { DataTableRenderedAt } from '@/constants/dataTableRenderedAt';
import useWatchlistCreditQualityInfiniteQuery from '@/hooks/query-hooks/watchlist-hooks/useWatchlistCreditQualityInfiniteQuery';
import {
  useCurrentBucket,
  useCurrentWatchlist,
} from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import useOnCheckedRow from '@/hooks/useOnCheckedRow';
import { ExtremumsLegend } from '@/partials/ExtremumsLegend';
import FundTablesActionToast from '@/partials/Toast/FundTablesActionToast';
import { switchTableRowColor } from '@/utils/generate-color';

import {
  flattenInfiniteQueryData,
  getTotalFromPaginatedData,
} from '../../utils';
import { scaleData } from '../../Watchlist/components/utils/scaleDataToOneHundredPercent';
import { notFIAndNotEquityBroadAssetClasses } from '../constants/notFIAndNotEquityBroadAssetClasses';
import { WatchlistCreditQualityColumns } from './WatchlistCreditQualityColumns';

const WatchlistCreditQualityTable: FC<{ isActive: boolean }> = ({
  isActive,
}) => {
  const history = useHistory();
  const currentBucket = useCurrentBucket();
  const [sortKey, setSortKey] = useState('fundName');
  const [sortDirection, setSortDirection] =
    useState<PageQueryParametersSortDirectionEnum>(
      PageQueryParametersSortDirectionEnum.Asc,
    );
  const { data, isLoading } = useWatchlistCreditQualityInfiniteQuery({
    sortKey: sortKey,
    sortDirection: sortDirection,
    bucketId: currentBucket?.id,
  });

  const [scaleToggleState, setScaleToggleState] = useState(false);

  const rawData = flattenInfiniteQueryData(data);
  const totalNumberOfItems = getTotalFromPaginatedData(data);

  const scaledData = useMemo(() => {
    let data = [];

    data = rawData.map(fund => {
      if (isFund(fund)) {
        return {
          ...fund,
          bondCreditQualityBreakdown: fund['bondCreditQualityBreakdown']
            ? scaleData(fund['bondCreditQualityBreakdown'])
            : [],
        };
      } else {
        return fund;
      }
    });
    return data;
  }, [rawData]);

  const flattenedData = scaleToggleState ? scaledData : rawData;

  const extremumsForCreditQuality = useMemo(
    () =>
      calculateExtremumsForFundByBroadAssetClasses({
        funds: flattenedData,
        filters: CREDIT_QUALITY_TO_SHOW.filter(s =>
          CreditQualityBreakdownTypeCodes.some(c => c.typeCode === s),
        ),
        specificFundField: 'bondCreditQualityBreakdown',
        broadAssetClassesToFilterBy: [
          ...notFIAndNotEquityBroadAssetClasses,
          FundBroadAssetClass.FixedIncome,
        ],
      }),
    [flattenedData],
  );

  const isins = useMemo(() => {
    return flattenedData.map(item => {
      if (isFund(item)) {
        return item.shareClassDetails.isin;
      }
    });
  }, [flattenedData]);

  const checkedRowState = useOnCheckedRow<Fund>(isins.length || 0);
  const { checkedRows, setCheckedRows, setIsAllCheckboxChecked } =
    checkedRowState;

  useEffect(() => {
    setCheckedRows([]);
    setScaleToggleState(false);
  }, [isActive]);

  const currentWatchlist = useCurrentWatchlist();
  const currentFundsList = useMemo(() => {
    if (currentBucket) {
      return currentBucket.funds;
    }
    return currentWatchlist.data?.buckets.flatMap(b => b.funds) ?? [];
  }, [currentWatchlist.data?.buckets, currentBucket]);

  const columns = WatchlistCreditQualityColumns({
    rowSelectionState: checkedRowState,
    creditQualityWithExtremums: extremumsForCreditQuality,
    currentBucket,
    onSelectAll: checked => {
      if (checked) {
        const topIsins = flattenedData
          .slice(0, LIMIT_FUNDS_FOR_CHARTING)
          .map(item => item.shareClassDetails.isin);
        setCheckedRows(topIsins ?? []);
      } else {
        setCheckedRows([]);
      }
    },
  });

  const totalIsinsPortal = document.getElementById('total-isins-portal');
  const scaleTogglePortal = document.getElementById('scale-toggle-portal');

  return (
    <>
      {isActive &&
        totalIsinsPortal &&
        ReactDOM.createPortal(
          <PageResults
            className={'min-w-28'}
            totalCount={totalNumberOfItems ?? 0}
          />,
          totalIsinsPortal,
        )}
      {isActive &&
        scaleTogglePortal &&
        ReactDOM.createPortal(
          <div className="flex justify-end align-center">
            <span className="text-xs pr-2 text-neutral-450">Scale to 100%</span>
            <Switch
              onChange={checked => setScaleToggleState(checked)}
              checked={scaleToggleState}
            />
          </div>,
          scaleTogglePortal,
        )}
      <DataTable
        className="watchlist-tr-padding"
        uniqueKey={TABLE_UNIQUE_KEY}
        loading={isLoading}
        columns={columns}
        data={flattenedData}
        onSort={async (
          key: string,
          direction: PageQueryParametersSortDirectionEnum,
        ) => {
          setSortKey(key);
          setSortDirection(direction);
        }}
        onRow={id => history.push(buildFundDetailsPath(id))}
        legendType={LEGEND_TYPE_ENUM.ESG}
        computeRowStyle={rowData => {
          const isFundRow = isFund(rowData);
          const isFeaturedRow = isFundRow
            ? currentFundsList.find(
                f => rowData.shareClassDetails.isin === f?.isin,
              )?.isFeatured ?? false
            : false;
          return {
            backgroundColor: switchTableRowColor(isFeaturedRow, !isFundRow),
          };
        }}
      />
      {<ExtremumsLegend />}
      {checkedRows.length > 0 && (
        <FundTablesActionToast
          isins={checkedRows}
          count={checkedRows?.length || 0}
          onClearCheckedRows={(isins: string[]) => {
            setCheckedRows(isins);
            setIsAllCheckboxChecked(false);
          }}
          checkedRowsClassDetails={checkedRows
            .slice(0, MAX_COMPARE_TOTAL)
            .map(i => getFundShareClassDetailsFromArray(flattenedData, i))}
          dataTableRenderedAt={DataTableRenderedAt.Watchlist}
        />
      )}
    </>
  );
};

export default WatchlistCreditQualityTable;
