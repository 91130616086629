import {
  DATE_PERIOD_FILTER,
  DISPLAY_DATE_FORMAT,
  EDateFilterValues,
} from '@aminsights/shared';
import { Dropdown as AntDropdown, Menu } from 'antd';
import cx from 'classnames';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import { ReactComponent as MeatballMenu } from '@/assets/svg/meatball-menu.svg';
import { Dropdown } from '@/components';
import Loader from '@/pages/app/FundAndInvestmentTrust/components/Loader';
import {
  InvestmentTrustReturnType,
  useDropDownContext,
} from '@/partials/Charts/Context';
import { useDatePickerContext } from '@/partials/DatePicker';
import { parseDateValue } from '@/partials/DatePicker/Kinds/RangeDatePicker';

import chartStyles from '../../Charts/utils/chart.module.less';

interface RiskReturnPlotTitleProps {
  hasInvestmentTrust?: boolean;
  showDropdown?: boolean;
  riskReturnLatestDate?: string;
}

export const allowedPeriodValuesForRiskReturn = [
  EDateFilterValues['1YR'],
  EDateFilterValues['3YR'],
  EDateFilterValues['5YR'],
];

const allowedPeriodsForDropdown = DATE_PERIOD_FILTER.filter(p =>
  allowedPeriodValuesForRiskReturn.some(apv => apv === p.value),
);

const RiskReturnPlotTitle: React.FCWithChild<RiskReturnPlotTitleProps> = ({
  showDropdown,
  hasInvestmentTrust,
  riskReturnLatestDate,
}) => {
  const { value: datePickerValue, handleChange: setPeriod } =
    useDatePickerContext();
  const { setValue: setInvestmentTrustFilter, valueLabel } =
    useDropDownContext();

  const period = useMemo(() => {
    if (allowedPeriodValuesForRiskReturn.includes(datePickerValue.mode)) {
      return datePickerValue.mode;
    }
    return EDateFilterValues['1YR'];
  }, [datePickerValue]);

  const humanizedPeriod = useMemo(
    () => DATE_PERIOD_FILTER.find(fd => fd.value === period)?.label,
    [period],
  );

  const chartFilterMenu = (
    <Menu
      triggerSubMenuAction="click"
      items={[
        {
          label: 'Investment Trust Return',
          key: 'InvestmentTrustReturn',
          disabled: !hasInvestmentTrust,
          children: (
            [
              { label: 'NAV', value: InvestmentTrustReturnType.NAV },
              {
                label: 'Share Price',
                value: InvestmentTrustReturnType.SharePrice,
              },
            ] as const
          ).map(item => ({
            key: item.label,
            label: (
              <div
                className={cx({
                  [chartStyles['chart-filter-menu-item-active']]:
                    valueLabel === item.value,
                  [chartStyles['chart-filter-menu-item-disabled']]:
                    !hasInvestmentTrust,
                })}
              >
                {item.label}
              </div>
            ),
            onClick: () => {
              setInvestmentTrustFilter(item.value);
            },
          })),
        },
      ]}
    />
  );

  return (
    <>
      {' '}
      <div className={chartStyles.header}>
        <h5
          data-test-id="riskReturnChartLabel"
          className="text-sm font-bold text-darkest"
        >
          <Loader width="150px" component={<>Risk/Return</>} />
        </h5>
        <div className="w-full col-span-2 justify-self-end sm:col-span-1 sm:w-auto">
          <Dropdown
            rounded
            label="Select value"
            dataTestId="riskReturnChartDropdown"
            value={period}
            onSelect={(selectedValue: EDateFilterValues) => {
              setPeriod(parseDateValue(selectedValue), selectedValue);
            }}
            items={allowedPeriodsForDropdown}
            defaultValue={period}
          />
        </div>
        {showDropdown && (
          <AntDropdown
            className={cx(
              chartStyles['icon-meatball'],
              'row-start-1 col-start-2 sm:col-start-3',
            )}
            overlay={chartFilterMenu}
            placement="bottomRight"
            trigger={['click']}
            overlayClassName={chartStyles['chart-filter-dropdown']}
          >
            <MeatballMenu />
          </AntDropdown>
        )}
      </div>
      <div className="w-full justify-self-end">
        {humanizedPeriod && riskReturnLatestDate && (
          <p className="text-xs md:text-right mt-2">
            <strong>{humanizedPeriod}</strong> as at{' '}
            <i>{dayjs(riskReturnLatestDate).format(DISPLAY_DATE_FORMAT)}</i>
          </p>
        )}
      </div>
    </>
  );
};

export default RiskReturnPlotTitle;
