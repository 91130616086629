/* eslint-disable @typescript-eslint/no-explicit-any */
import { NotificationsSettingsForSignificantChanges } from '@aminsights/contract';
import { ChangeTypeEnum, ChangeTypeLabels } from '@aminsights/shared';
import { Checkbox } from 'antd';
import cx from 'classnames';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';

import style from './style.module.less';

export type NotificationType = keyof NotificationsSettingsForSignificantChanges;

type NotificationModalChecklistProps = {
  setChecked: (
    key: ChangeTypeEnum,
    type: NotificationType,
    value?: boolean,
  ) => void;
  setAllChecked: (type: NotificationType, value: boolean) => void;
  notificationSettings: NotificationsSettingsForSignificantChanges;
};

const NotificationModalChecklist: React.FCWithChild<
  NotificationModalChecklistProps
> = ({ notificationSettings, setChecked, setAllChecked }) => {
  const { inApp, email } = notificationSettings;

  const { state: featureSwitchState } = useFeatureSwitchContext();

  const [allAppChecked, setAllAppChecked] = React.useState<boolean>(false);
  const [allEmailChecked, setAllEmailChecked] = React.useState<boolean>(false);
  useEffect(() => {
    setAllAppChecked(
      Object.values(inApp).every((value: boolean) => value === true),
    );
    setAllEmailChecked(
      Object.values(email).every((value: boolean) => value === true),
    );
  }, [inApp, email]);

  return (
    <div className={style['checklist-wrapper']}>
      <div className={style['checklist-description']}>
        <p>
          Your notifications will be based on the parameters you have selected
          in your{' '}
          <Link
            to="/settings/significant-changes"
            data-test-id="significantChangesLink"
          >
            Significant Changes settings
          </Link>
        </p>
      </div>
      <div className={style['checklist-content']}>
        <div
          className={cx(
            style['checklist-table-row--row1'],
            style['checklist-table--header'],
          )}
        >
          <p>Notifications</p>
        </div>
        <div
          className={cx(
            style['checklist-table-row--row2'],
            style['checklist-table--header'],
          )}
        >
          <p>In-App</p>
        </div>
        <div
          className={cx(
            style['checklist-table-row--row3'],
            style['checklist-table--header'],
          )}
        >
          <p>Email</p>
        </div>
        <div className={cx(style['checklist-table-row--row1'])}>Select All</div>
        <div className={style['checklist-table-row--row2']}>
          <Checkbox
            onChange={() => {
              setAllChecked('inApp', !allAppChecked);
              setAllAppChecked(!allAppChecked);
            }}
            checked={allAppChecked}
            data-test-id="notificationsInAppAllCheckbox"
          />
        </div>

        <div className={style['checklist-table-row--row3']}>
          <Checkbox
            onChange={() => {
              setAllChecked('email', !allEmailChecked);
              setAllEmailChecked(!allEmailChecked);
            }}
            checked={allEmailChecked}
            data-test-id="notificationsEmailAllCheckbox"
          />
        </div>
        <div className={style['checklist-content--divider']} />
        {Object.entries(ChangeTypeLabels)
          .filter(([key, _value]) => {
            if (key === ChangeTypeEnum.Flow) {
              return featureSwitchState.isScFlowTreatmentEnabled; //remove whole filter, when remove isScFlowTreatmentEnabled
            }
            return true;
          })
          .map(([key, value]) => (
            <React.Fragment key={key}>
              <div className={style['checklist-table-row--row1']}>{value}</div>
              <div className={style['checklist-table-row--row2']}>
                <Checkbox
                  onChange={() => setChecked(key as ChangeTypeEnum, 'inApp')}
                  checked={notificationSettings.inApp[key as ChangeTypeEnum]}
                  key={key}
                />
              </div>
              <div className={style['checklist-table-row--row3']}>
                <Checkbox
                  onChange={() => setChecked(key as ChangeTypeEnum, 'email')}
                  checked={notificationSettings.email[key as ChangeTypeEnum]}
                  key={key}
                />
              </div>
            </React.Fragment>
          ))}
      </div>
    </div>
  );
};

export default NotificationModalChecklist;
