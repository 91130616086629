import { Fund } from '@aminsights/contract';
import { EMPTY_DATA_POINT, LIMIT_FUNDS_FOR_CHARTING } from '@aminsights/shared';
import { Checkbox, Skeleton } from 'antd';
import React from 'react';

import { IDataTableColumns } from '@/components/Table/DataTable';
import { UseOnCheckedRowReturn } from '@/hooks/useOnCheckedRow';
import FundInvestmentTrustColumn from '@/partials/columns/FundInvestmentTrustColumn';
import { FundSizeColumn } from '@/partials/columns/FundSizeColumn';
import GetManagersColumn from '@/partials/columns/GetManagersColumn';
import { roundOrEmpty } from '@/utils/roundOrEmpty';

import { columnizer } from '../../utils';
import styles from '../style.module.less';

type GetWatchlistSummaryColumns = {
  rowSelectionState: UseOnCheckedRowReturn<Fund>;
  allItemsAreFixedIncome: boolean;
  onSelectAll: (isChecked: boolean) => void;
  hasInvestmentTrust: boolean;
};

export const getWatchlistSummaryColumns = ({
  rowSelectionState,
  allItemsAreFixedIncome,
  onSelectAll,
  hasInvestmentTrust,
}: GetWatchlistSummaryColumns): Array<IDataTableColumns> => {
  const { onCheckedRow, checkedRows, isAllCheckboxChecked } = rowSelectionState;

  const columnDefinitions = columnizer(
    {
      title: 'Fund/Investment Trust',
      sortKey: 'fundName',
      idSubText: 'fundName',
      headerCheckbox: (): React.ReactNode => (
        <div className="relative -left-4" onClick={e => e.stopPropagation()}>
          <Checkbox
            onClick={(event: React.MouseEvent<HTMLInputElement>) => {
              const isChecked = event.currentTarget.checked;
              onSelectAll(isChecked);
              event.stopPropagation();
            }}
            checked={isAllCheckboxChecked}
            className={styles['header-checkbox']}
          />
        </div>
      ),
      render: (item: Fund) => (
        <div className="flex items-center">
          <div className="relative -left-4" onClick={e => e.stopPropagation()}>
            <Checkbox
              value={item.shareClassDetails.isin}
              onChange={e => onCheckedRow([e.target.value])}
              onClick={e => e.stopPropagation()}
              disabled={
                checkedRows.length >= LIMIT_FUNDS_FOR_CHARTING &&
                !checkedRows.includes(item.shareClassDetails.isin)
              }
              checked={checkedRows.includes(item.shareClassDetails.isin)}
              className={styles['body-checkbox']}
            />
          </div>
          <div className="relative -left-3">
            <FundInvestmentTrustColumn
              data={{
                id: item.shareClassDetails.isin,
                name: item.shareClassDetails.code,
                type: item.legalStructure,
                assetClassCode: item.assetClassCode,
                fundId: item.fundId,
                legalStructure: item.legalStructure,
              }}
            />
          </div>
        </div>
      ),
      renderType: 'text',
      isColumnFixed: true,
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'Manager',
      idSubText: 'manager',
      render: (item: Fund) => <GetManagersColumn fund={item} />,
      renderType: 'text',
      className: 'whitespace-nowrap',
      width: 170,
    },

    {
      title: 'Fund Size',
      render: (fund: Fund) => <FundSizeColumn fund={fund} />,
      renderType: 'number',
      sortKey: 'fundSize.value',
      idSubText: 'fundSize.value',
      width: 100,
      tooltipText: 'Market cap for Inv Trusts',
    },
    hasInvestmentTrust && {
      title: 'Prem/Disc',
      render: (item: Fund): React.ReactElement => {
        return (
          <div className="text-sm text-neutral">
            {!item.premiumDiscount?.current
              ? EMPTY_DATA_POINT
              : `${Number(item.premiumDiscount.current).toFixed(1)}%`}
          </div>
        );
      },
      renderType: 'number' as const,
      sortKey: 'premiumDiscount.current',
      idSubText: 'premiumDiscount.current',
      width: 100,
    },
    !allItemsAreFixedIncome && {
      title: 'No. of Holdings',
      align: 'right',
      sortKey: 'numberOfHoldings',
      idSubText: 'numberOfHoldings',
      render: (item: Fund) => (
        <div className="text-sm text-right text-neutral width-100">
          {!item?.numberOfHoldings && item?.numberOfHoldings !== 0
            ? EMPTY_DATA_POINT
            : item?.numberOfHoldings}
        </div>
      ),
      renderType: 'number' as const,
      width: 130,
    },
    allItemsAreFixedIncome && {
      title: 'Mod. Duration',
      sortKey: 'broadAssetClassValues.bonds.modifiedDuration',
      idSubText: 'modifiedDuration',
      render: (item: Fund): React.ReactElement => {
        const value = roundOrEmpty(
          item.broadAssetClassValues?.bonds?.modifiedDuration,
          1,
        );
        return <div className="text-sm text-neutral">{value}</div>;
      },
      renderType: 'number' as const,
      width: 130,
    },
    allItemsAreFixedIncome && {
      title: 'Avg. Credit Quality',
      sortKey: 'broadAssetClassValues.bonds.averageCreditQualityDefinition',
      idSubText: 'avgCreditQuality',
      render: (item: Fund): React.ReactElement => {
        const value =
          item.broadAssetClassValues?.bonds?.averageCreditQualityDefinition ??
          EMPTY_DATA_POINT;

        return <div className="text-sm text-neutral">{value}</div>;
      },
      renderType: 'number',
      width: 140,
    },
    allItemsAreFixedIncome && {
      title: 'YTM',
      sortKey: 'broadAssetClassValues.bonds.yieldToMaturity',
      idSubText: 'yieldToMaturity',
      render: (item: Fund) => {
        const value = roundOrEmpty(
          item.broadAssetClassValues?.bonds?.yieldToMaturity,
          1,
          '%',
        );

        return <div className="text-sm text-neutral">{value}</div>;
      },
      renderType: 'number' as const,
      width: 90,
    },
    {
      title: 'OCF',
      sortKey: 'ocfPct',
      idSubText: 'ocfPct',
      render: (item: Fund) => {
        const value = roundOrEmpty(item.ocfPct, 2, '%');
        return <div className="text-sm text-neutral">{value}</div>;
      },
      renderType: 'number',
      width: 100,
    },
  );

  return columnDefinitions;
};
