import getSymbolFromCurrency from 'currency-symbol-map';

import {
  ChangeTypeEnum,
  DEFAULT_CURRENCY_CODE,
  EMPTY_DATA_POINT,
  FUND_BOX_X_AND_Y_LABEL_VALUE,
  getShortHumanReadableStyleboxName,
} from '../constants';
import { isFixedIncomeBroadAssetClass, shortenLongNumber } from '../utils';

export const formatChangeType = (
  changeType: string,
  value: number,
  broadAssetClass: string | undefined,
  formatFlow: boolean = false,
) => {
  if (!value) {
    return EMPTY_DATA_POINT;
  }
  if (changeType === ChangeTypeEnum.FundSize) {
    return `${getSymbolFromCurrency(DEFAULT_CURRENCY_CODE)}${shortenLongNumber(
      +value.toFixed(1) ?? 0,
    )}`;
  }
  if (formatFlow) {
    const isNegative = value < 0;
    const valueAbs = Math.abs(value);
    const formattedValue = `${getSymbolFromCurrency(DEFAULT_CURRENCY_CODE)}${
      shortenLongNumber(+valueAbs.toFixed(1)) ?? 0
    }`;

    return isNegative ? `-${formattedValue}` : formattedValue;
  }
  if (
    changeType === ChangeTypeEnum.Holdings ||
    changeType === ChangeTypeEnum.ModifiedDuration
  ) {
    return +value.toFixed(2);
  }

  if (changeType === ChangeTypeEnum.StyleDrift) {
    if (!broadAssetClass) return EMPTY_DATA_POINT;

    const boxLabels = isFixedIncomeBroadAssetClass(broadAssetClass)
      ? FUND_BOX_X_AND_Y_LABEL_VALUE.FI
      : FUND_BOX_X_AND_Y_LABEL_VALUE.EQ;
    return getShortHumanReadableStyleboxName(value, boxLabels);
  }

  return `${value.toFixed(1)}%`;
};
