import { EDateFilterValues, formatNumberDecimals } from '@aminsights/shared';
import { ScatterConfig } from '@ant-design/charts';
import React, { useEffect, useMemo, useState } from 'react';

import loaderImage from '@/assets/images/graph-mask.png';
import useGetRiskReturnDataWithPortfolio from '@/hooks/query-hooks/chart-hooks/useGetRiskReturnDataWithPortfolio';
import Loader from '@/pages/app/FundAndInvestmentTrust/components/Loader';
import ChartEmptyState from '@/partials/Charts/ChartEmptyState';
import { BaseChartPropsV2 } from '@/partials/Charts/components/BaseChart';
import { useDropDownContext } from '@/partials/Charts/Context';
import { STROKE_GREY } from '@/partials/Charts/utils/colors';
import { useChartColorsWithIsinsAndPortfolio } from '@/partials/Charts/utils/useChartColors';
import { useDatePickerContext } from '@/partials/DatePicker';
import ScatterPlotLegendCard from '@/partials/LegendCards/ScatterPlotLegendCard';
import BaseScatterPlot from '@/partials/ScatterPlots/components/BaseScatterPlot';
import { ScatterPlotCardDetailsWithPortfolio } from '@/partials/ScatterPlots/utils/scatter-plot-data';

import { allowedPeriodValuesForRiskReturn } from './RiskReturnScatterPlotV2';

interface AbsoluteChartWithPortfolioProps
  extends Omit<BaseChartPropsV2, 'onRemoveFund'> {
  hasInvestmentTrust?: boolean;
  showDropdown?: boolean;
  scrollable?: boolean;
  portfolios?: string[];
  onRemoveFund?: (id: string, isPortfolio?: boolean) => void;
  riskReturnLatestDate?: string;
  updateRiskReturnLatestDate: (date?: string) => void;
}

const RiskReturnV2WithPortfolio: React.FCWithChild<
  AbsoluteChartWithPortfolioProps
> = ({
  showFundLinks = true,
  emptyState,
  onRemoveFund,
  isins,
  benchmarkId,
  featuredIsins,
  portfolios,
  chartColors: chartColorsProps,
  riskReturnLatestDate,
  updateRiskReturnLatestDate,
}) => {
  const { value: investmentTrustFilter } = useDropDownContext();
  const { value: datePickerValue } = useDatePickerContext();
  const [legendData, setLegendData] = useState<
    ScatterPlotCardDetailsWithPortfolio[]
  >([]);

  const period = useMemo(() => {
    if (allowedPeriodValuesForRiskReturn.includes(datePickerValue.mode)) {
      return datePickerValue.mode;
    }
    return EDateFilterValues['1YR'];
  }, [datePickerValue]);

  const { data, isLoading } = useGetRiskReturnDataWithPortfolio({
    benchmarkId,
    isins,
    riskDetailsType: investmentTrustFilter.risk,
    featuredIsins,
    portfolios,
    period,
  });

  const chartColorsHook = useChartColorsWithIsinsAndPortfolio({
    isins,
    legend: data?.legendData || [],
    portfolioLegendIds: portfolios,
  });
  const scatterPlotColors = chartColorsProps || chartColorsHook;

  const scatterPlotData = useMemo(
    () =>
      data?.data?.map(s => ({
        ...s,
        standardDeviation: formatNumberDecimals(s.standardDeviation || 0, 1),
        totalReturnOverPeriod: formatNumberDecimals(
          s.totalReturnOverPeriod || 0,
          1,
        ),
        color: scatterPlotColors[s.id],
      })) || [],
    [data, scatterPlotColors],
  );

  useEffect(() => {
    if (data?.legendData?.length && scatterPlotData) {
      setLegendData(
        data.legendData.map(f => ({
          ...f,
          tooltip: f.label || '',
          standardDeviation: f.xValue || 0,
          totalReturnOverPeriod: f.yValue || 0,
          id: f.id || '',
          isBenchmark: f.isBenchmark || false,
          label: f.label || '',
          isFeatured: f.isFeatured || false,
          color: scatterPlotColors[f.id || ''] || '',
        })),
      );
    }
  }, [data, scatterPlotColors]);

  useEffect(() => {
    if (isLoading) return;

    const to = data?.queriedDateRange?.to;
    if (to !== riskReturnLatestDate) {
      updateRiskReturnLatestDate(to);
    }
  }, [data, isLoading]);

  const config: ScatterConfig = useMemo(
    () => ({
      className: 'custom-antd-scatter-plot',
      xField: '',
      yField: '',
      data: scatterPlotData,
      colorField: 'color',
      color: data => {
        return data.color;
      },
    }),
    [scatterPlotData, scatterPlotColors],
  );

  if (!scatterPlotData.length && !isLoading) {
    return (
      <ChartEmptyState
        subtitle={emptyState?.subTitle || 'Search funds/portfolios to chart'}
        title={emptyState?.title || 'No data for this time period'}
        isDashboardPage={true}
      />
    );
  }

  return (
    <div>
      {!isLoading && (
        <div data-test-id="riskReturnChart">
          <BaseScatterPlot
            config={config}
            strokeColor={STROKE_GREY}
            onUpdateLegend={setLegendData}
          />
        </div>
      )}
      {isLoading && <img className="w-full mt-5 mb-5" src={loaderImage} />}
      <div
        data-test-id="riskReturnWithPortfolioLegend"
        className="mt-2 grid grid-cols-1 gap-x-6 gap-y-2 md:grid-cols-2 lg:grid-cols-3"
      >
        <Loader
          row={2}
          width="150px"
          loading={isLoading}
          component={legendData
            .filter(f => f.label)
            .map(legendItem => {
              const id = !legendItem.isPortfolio
                ? legendItem.isin
                : legendItem.id.split('_')[0];
              return (
                <ScatterPlotLegendCard
                  key={legendItem.id}
                  label={legendItem.label}
                  idForFundDetailsLink={
                    showFundLinks ? legendItem.isin : undefined
                  }
                  id={legendItem.id}
                  tooltip={legendItem.tooltip || legendItem.label}
                  isIndex={legendItem.isBenchmark}
                  color={legendItem.color}
                  isFeatured={
                    !!featuredIsins?.length ? legendItem.isFeatured : false
                  }
                  onRemove={() => {
                    onRemoveFund?.(id || '', legendItem.isPortfolio);
                  }}
                  isActive={legendItem.isActive}
                  totalReturnOverPeriod={legendItem.totalReturnOverPeriod}
                  standardDeviation={legendItem.standardDeviation}
                  targetLink={`/portfolios/${id}/holdings`}
                  superLabel={legendItem.isPortfolio ? 'Portfolio' : ''}
                />
              );
            })}
        />
      </div>
    </div>
  );
};

export default RiskReturnV2WithPortfolio;
