import {
  AssetAllocationItemTypeEnum,
  Fund,
  FundBroadAssetClass,
  PageQueryParametersSortDirectionEnum,
} from '@aminsights/contract';
import {
  buildFundDetailsPath,
  calculateExtremumsForFundByBroadAssetClasses,
  getFundShareClassDetailsFromArray,
  isFund,
  LIMIT_FUNDS_FOR_CHARTING,
} from '@aminsights/shared';
import { FC, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';

import { DataTable, PageResults } from '@/components';
import {
  LEGEND_TYPE_ENUM,
  MAX_COMPARE_TOTAL,
  TABLE_UNIQUE_KEY,
} from '@/constants';
import { DataTableRenderedAt } from '@/constants/dataTableRenderedAt';
import useWatchlistAssetAllocationInfiniteQuery from '@/hooks/query-hooks/watchlist-hooks/useWatchlistAssetAllocationInfiniteQuery';
import { useCurrentBucket } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import useOnCheckedRow from '@/hooks/useOnCheckedRow';
import { ExtremumsLegend } from '@/partials/ExtremumsLegend';
import FundTablesActionToast from '@/partials/Toast/FundTablesActionToast';

import {
  flattenInfiniteQueryData,
  getTotalFromPaginatedData,
} from '../../utils';
import { notFIAndNotEquityBroadAssetClasses } from '../constants/notFIAndNotEquityBroadAssetClasses';
import AssetAllocationColumns from './WatchlistAssetAllocationColumns';

const AssetAllocationTable: FC<{ isActive: boolean }> = ({ isActive }) => {
  const history = useHistory();
  const currentBucket = useCurrentBucket();
  const [sortKey, setSortKey] = useState('fundName');
  const [sortDirection, setSortDirection] =
    useState<PageQueryParametersSortDirectionEnum>(
      PageQueryParametersSortDirectionEnum.Asc,
    );
  const { data, isLoading } = useWatchlistAssetAllocationInfiniteQuery({
    sortKey: sortKey,
    sortDirection: sortDirection,
    bucketId: currentBucket?.id,
  });

  const flattenedData = flattenInfiniteQueryData(data);
  const totalNumberOfItems = getTotalFromPaginatedData(data);

  const isins = useMemo(() => {
    return flattenedData.map(item => {
      if (isFund(item)) {
        return item.shareClassDetails.isin;
      }
    });
  }, [flattenedData]);

  const checkedRowState = useOnCheckedRow<Fund>(isins.length || 0);
  const { checkedRows, setCheckedRows, setIsAllCheckboxChecked } =
    checkedRowState;

  useEffect(() => {
    setCheckedRows([]);
  }, [isActive]);

  const assetAllocationWithExtremums = useMemo(
    () =>
      calculateExtremumsForFundByBroadAssetClasses({
        funds: flattenedData, // TODO: Seems like Performance type is part of the array before anyway, so we should add it to the type
        filters: [
          AssetAllocationItemTypeEnum.Equities,
          AssetAllocationItemTypeEnum.FixedIncome,
          AssetAllocationItemTypeEnum.Cash,
          AssetAllocationItemTypeEnum.Others,
          AssetAllocationItemTypeEnum.Convertibles,
          AssetAllocationItemTypeEnum.Preferred,
        ],
        specificFundField: 'assetAllocation',
        broadAssetClassesToFilterBy: [
          ...notFIAndNotEquityBroadAssetClasses,
          FundBroadAssetClass.Equity,
          FundBroadAssetClass.FixedIncome,
        ],
      }),
    [flattenedData],
  );

  const columns = AssetAllocationColumns({
    rowSelectionState: checkedRowState,
    currentBucket,
    onSelectAll: checked => {
      if (checked) {
        const topIsins = flattenedData
          .slice(0, LIMIT_FUNDS_FOR_CHARTING)
          .map(item => item.shareClassDetails.isin);
        setCheckedRows(topIsins ?? []);
      } else {
        setCheckedRows([]);
      }
    },
    assetAllocationWithExtremums,
  });

  const totalIsinsPortal = document.getElementById('total-isins-portal');

  return (
    <>
      {isActive &&
        totalIsinsPortal &&
        ReactDOM.createPortal(
          <PageResults
            className={'min-w-28'}
            totalCount={totalNumberOfItems ?? 0}
          />,
          totalIsinsPortal,
        )}
      <DataTable
        className="watchlist-tr-padding"
        uniqueKey={TABLE_UNIQUE_KEY}
        loading={isLoading}
        columns={columns}
        data={flattenedData}
        onSort={async (
          key: string,
          direction: PageQueryParametersSortDirectionEnum,
        ) => {
          setSortKey(key);
          setSortDirection(direction);
        }}
        onRow={id => history.push(buildFundDetailsPath(id))}
        legendType={LEGEND_TYPE_ENUM.EXTREMUM}
      />
      {<ExtremumsLegend />}
      {checkedRows.length > 0 && (
        <FundTablesActionToast
          isins={checkedRows}
          count={checkedRows?.length || 0}
          onClearCheckedRows={(isins: string[]) => {
            setCheckedRows(isins);
            setIsAllCheckboxChecked(false);
          }}
          checkedRowsClassDetails={checkedRows
            .slice(0, MAX_COMPARE_TOTAL)
            .map(i => getFundShareClassDetailsFromArray(flattenedData, i))}
          dataTableRenderedAt={DataTableRenderedAt.Watchlist}
        />
      )}
    </>
  );
};

export default AssetAllocationTable;
