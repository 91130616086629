import { PageQueryParametersSortDirectionEnum } from '@aminsights/contract';
import { buildFundDetailsPath, isFund } from '@aminsights/shared';
import { FC, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';

import { DataTable, PageResults } from '@/components';
import { LEGEND_TYPE_ENUM, TABLE_UNIQUE_KEY } from '@/constants';
import useWatchlistESGInfiniteQuery from '@/hooks/query-hooks/watchlist-hooks/useWatchlistESGInfiniteQuery';
import {
  useCurrentBucket,
  useCurrentWatchlist,
} from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import { useInfiniteScroll } from '@/hooks/useInfiniteScroll';
import { ESGLegend } from '@/partials/ESG/ESGLegend';
import { switchTableRowColor } from '@/utils/generate-color';

import {
  flattenInfiniteQueryData,
  getTotalFromPaginatedData,
} from '../../utils';
import WatchlistESGColumns from './WatchlistESGColumns';

const WatchlistSectorsEqTable: FC<{ isActive: boolean }> = ({ isActive }) => {
  const history = useHistory();
  const currentBucket = useCurrentBucket();
  const [sortKey, setSortKey] = useState('fundName');
  const [sortDirection, setSortDirection] =
    useState<PageQueryParametersSortDirectionEnum>(
      PageQueryParametersSortDirectionEnum.Asc,
    );
  const { data, isLoading, fetchNextPage } = useWatchlistESGInfiniteQuery({
    sortKey: sortKey,
    sortDirection: sortDirection,
    bucketId: currentBucket?.id,
  });

  const flattenedData = flattenInfiniteQueryData(data);
  const totalNumberOfItems = getTotalFromPaginatedData(data);

  const { setPage, lastElementRef } = useInfiniteScroll(
    totalNumberOfItems,
    flattenedData,
    ({ page }) => {
      if (page > 1) {
        fetchNextPage({ pageParam: page });
      }
    },
    [currentBucket?.id, sortDirection],
  );

  const currentWatchlist = useCurrentWatchlist();

  const currentFundsList = useMemo(() => {
    if (currentBucket) {
      return currentBucket.funds;
    }
    return currentWatchlist.data?.buckets.flatMap(b => b.funds) ?? [];
  }, [currentWatchlist.data?.buckets, currentBucket]);

  const columns = WatchlistESGColumns();

  const totalIsinsPortal = document.getElementById('total-isins-portal');

  return (
    <>
      {isActive &&
        totalIsinsPortal &&
        ReactDOM.createPortal(
          <PageResults
            className={'min-w-28'}
            totalCount={totalNumberOfItems ?? 0}
          />,
          totalIsinsPortal,
        )}
      <DataTable
        className="watchlist-tr-padding"
        lastElementRef={lastElementRef}
        uniqueKey={TABLE_UNIQUE_KEY}
        loading={isLoading}
        columns={columns}
        data={flattenedData}
        onSort={async (
          key: string,
          direction: PageQueryParametersSortDirectionEnum,
        ) => {
          setSortKey(key);
          setSortDirection(direction);
          setPage(1);
        }}
        computeRowStyle={rowData => {
          const isFundRow = isFund(rowData);
          const isFeaturedRow = isFundRow
            ? currentFundsList.find(
                f => rowData.shareClassDetails.isin === f?.isin,
              )?.isFeatured ?? false
            : false;
          return {
            backgroundColor: switchTableRowColor(isFeaturedRow, !isFundRow),
          };
        }}
        onRow={id => history.push(buildFundDetailsPath(id))}
        legendType={LEGEND_TYPE_ENUM.ESG}
      />
      {<ESGLegend />}
    </>
  );
};

export default WatchlistSectorsEqTable;
