import { Fund } from '@aminsights/contract';
import { EMPTY_DATA_POINT, shortenLongNumber } from '@aminsights/shared';
import { Skeleton } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import React from 'react';

import { IDataTableColumns } from '@/components/Table/DataTable';
import { DataTableRenderedAt } from '@/constants/dataTableRenderedAt';
import useGetCurrencyRates from '@/hooks/query-hooks/currency-hooks/useGetCurrencyRates';
import { TableCheckboxHeader } from '@/pages/app/Explore/components/TableCheckbox';

import FundNameCheckboxWrapper, {
  CheckboxParams,
} from './FundNameCheckboxWrapper';
import GetManagersColumn from './GetManagersColumn';

/**
 * @deprecated - please use a column definition defined under watchlist or explore on its own
 * As you can see below `dataTableRenderedAt` which is an enum/flag to determine where the table is rendered is
 * simply a bad approach.
 */
const SummaryColumns = (
  checkboxParams: CheckboxParams,
  dataTableRenderedAt: DataTableRenderedAt,
  showPrimaryShareClassIndicator?: boolean,
  allItemsAreFixedIncome?: boolean,
): IDataTableColumns[] => {
  const columns: IDataTableColumns[] = [
    {
      headerCheckbox: (): React.ReactNode =>
        dataTableRenderedAt !== DataTableRenderedAt.Watchlist && (
          <TableCheckboxHeader
            onClick={checkboxParams.onCheckedRow}
            topISINs={checkboxParams.topISINs}
            checked={checkboxParams.isAllCheckboxChecked}
          />
        ),
      title: 'Fund/Investment Trust',
      sortKey: 'shareClassDetails.code',
      render: (item: Fund): React.ReactNode => (
        <FundNameCheckboxWrapper
          item={item}
          checkboxParams={checkboxParams}
          dataTableRenderedAt={dataTableRenderedAt}
          showPrimaryShareClassIndicator={
            showPrimaryShareClassIndicator ?? false
          }
        />
      ),
      renderType: 'text',
      isColumnFixed: true,
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title:
        dataTableRenderedAt === DataTableRenderedAt.Explore ? 'Category' : '',
      render:
        dataTableRenderedAt === DataTableRenderedAt.Explore
          ? (item: Fund) => item.msCategoryDefinition
          : () => '',
      sortKey:
        dataTableRenderedAt === DataTableRenderedAt.Explore
          ? 'msCategoryDefinition'
          : '',
      idSubText: 'msCategoryDefinition',
      renderType: 'text',
    },
    {
      title: 'Manager',
      idSubText: 'manager',
      render: (item: Fund) => <GetManagersColumn fund={item} />,
      renderType: 'text',
      className: 'whitespace-nowrap',
      width: 170,
    },
    {
      title: 'Fund Size',
      render: (item: Fund): React.ReactElement => {
        const { data: currencyData } = useGetCurrencyRates();

        return (
          <div className="text-sm text-neutral">
            {!item ||
            !item.fundSize ||
            !item.fundSize?.value ||
            !currencyData?.currencyCode
              ? EMPTY_DATA_POINT
              : `${getSymbolFromCurrency(
                  currencyData.currencyCode,
                )}${shortenLongNumber(item.fundSize.value)}`}
          </div>
        );
      },
      renderType: 'number',
      sortKey: 'fundSize.value',
      idSubText: 'fundSiz.value',
      width: 100,
      tooltipText: 'Market cap for Inv Trusts',
    },
    ...(!allItemsAreFixedIncome
      ? [
          {
            title: 'No. of Holdings',
            align: 'right',
            sortKey: 'numberOfHoldings',
            idSubText: 'numberOfHoldings',
            render: (item: Fund): React.ReactElement => {
              return (
                <div className="text-sm text-neutral text-right width-100">
                  {!item?.numberOfHoldings && item?.numberOfHoldings !== 0
                    ? EMPTY_DATA_POINT
                    : item?.numberOfHoldings}
                </div>
              );
            },
            renderType: 'number' as const,
            width: 130,
          },
        ]
      : []),
    ...(allItemsAreFixedIncome
      ? [
          {
            title: 'Mod. Duration',
            sortKey: 'broadAssetClassValues.bonds.modifiedDuration',
            idSubText: 'modifiedDuration',
            render: (item: Fund): React.ReactElement => {
              return (
                <div className="text-sm text-neutral">
                  {item &&
                    (item.broadAssetClassValues?.bonds?.modifiedDuration
                      ? item.broadAssetClassValues?.bonds?.modifiedDuration.toFixed(
                          1,
                        )
                      : EMPTY_DATA_POINT)}
                </div>
              );
            },
            renderType: 'number' as const,
            width: 130,
          },
          {
            title: 'Avg. Credit Quality',
            sortKey:
              'broadAssetClassValues.bonds.averageCreditQualityDefinition',
            idSubText: 'avgCreditQuality',
            render: (item: Fund): React.ReactElement => {
              return (
                <div className="text-sm text-neutral">
                  {item &&
                    (item.broadAssetClassValues?.bonds
                      ?.averageCreditQualityDefinition
                      ? item.broadAssetClassValues?.bonds
                          ?.averageCreditQualityDefinition
                      : EMPTY_DATA_POINT)}
                </div>
              );
            },
            renderType: 'number' as const,
            width: 140,
          },
          {
            title: 'YTM',
            sortKey: 'broadAssetClassValues.bonds.yieldToMaturity',
            idSubText: 'yieldToMaturity',
            render: (item: Fund): React.ReactElement => {
              return (
                <div className="text-sm text-neutral">
                  {item &&
                    (item.broadAssetClassValues?.bonds?.yieldToMaturity
                      ? `${item.broadAssetClassValues?.bonds?.yieldToMaturity.toFixed(
                          1,
                        )}%`
                      : EMPTY_DATA_POINT)}
                </div>
              );
            },
            renderType: 'number' as const,
            width: 90,
          },
        ]
      : []),
    {
      title: 'OCF',
      sortKey: 'ocfPct',
      idSubText: 'ocfPct',
      render: (item: Fund): React.ReactElement => {
        return (
          <div className="text-sm text-neutral">
            {!item.ocfPct ? EMPTY_DATA_POINT : `${item.ocfPct.toFixed(2)}%`}
          </div>
        );
      },
      renderType: 'number',
      width: 100,
    },
  ];

  return columns;
};

export default SummaryColumns;
